import { useHistory } from 'react-router-dom';
import { FormProvider, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import { Flex, Text, useColorModeValue, useToast } from '@chakra-ui/react';

import { isAfter, isValid, parse, subDays } from 'date-fns';

import { useCreateNewsletter } from 'hooks/newsletters';

import Card from 'components/Card/Card';
import CardBody from 'components/Card/CardBody';
import CardHeader from 'components/Card/CardHeader';
import NewsletterForm from './NewsletterForm';

const validateDate = (field) => {
	const explodeDate = field.split('/');
	const date = `${explodeDate[1]}-${explodeDate[0]}-${explodeDate[2]}`;
	if (!isValid(new Date(date))) return false;
	return true;
};

const schema = yup.object().shape({
	title: yup.string().required('O campo Título é obrigatório'),
	url: yup.string().required('O campo URL é obrigatório'),
	scheduled_to: yup
		.string()
		.required('O campo Data de Envio é obrigatório')
		.test('date-valid', 'Data inválida', (field) => validateDate(field))
		.test('future-date', 'Insira uma data maior ou igual a hoje', (field) =>{
			const today = subDays(new Date(), 1);
			const inputDate = parse(field, 'dd/MM/yyyy', new Date());
			return isAfter(inputDate, today);
		})
});

const CreateNewsletter = () => {
	const methods = useForm({
		mode: 'onBlur',
		reValidateMode: 'onBlur',
		resolver: yupResolver(schema)
	});
	const toast = useToast();
	const history = useHistory();

	const { reset } = methods;

	const [createNewsletter, { loading }] = useCreateNewsletter({
		onSuccess: (data) => {
			reset();
			toast({
				title: 'Newsletter cadastrado com sucesso.',
				status: 'success',
				duration: 5000,
				isClosable: true
			});
			if(history)
				history.push(
					`/sistema/administracao/newsletters/${data.createNewsletter.id}`
				);
		},
		onError: (error) => {
			const errorsArr = error ? Object.keys(error) : null;
			if (errorsArr)
				errorsArr.map((err) => {
					if (error[err]) {
						toast({
							title: error[err][0],
							status: 'error',
							duration: 5000,
							isClosable: true
						});
					}
				});
			else
				toast({
					title: 'Ocorreu um erro',
					status: 'error',
					duration: 5000,
					isClosable: true
				});
		}
	});

	const onSubmit = (data) => {
		const explodeDate = data.scheduled_to.split('/');
		const scheduledTo = `${explodeDate[2]}-${explodeDate[1]}-${explodeDate[0]}`;
		const variables = {
			input: {
				...data,
				scheduled_to: scheduledTo
			}
		};

		createNewsletter({ variables });
	};

	const textColor = useColorModeValue('gray.700', 'white');

	return (
		<Flex
			direction="column"
			minH="100vh"
			align="center"
			pt={{ sm: '120px', lg: '160px' }}
		>
			<Card w={{ md: '100%', lg: '50%' }}>
				<CardHeader mb="40px">
					<Flex direction="column">
						<Text
							color={textColor}
							fontSize="lg"
							fontWeight="bold"
							mb="3px"
						>
							Cadastrar Newsletter
						</Text>
						<Text
							color="gray.400"
							fontWeight="normal"
							fontSize="sm"
						>
							Preencha os campos para realizar o cadastro
						</Text>
					</Flex>
				</CardHeader>
				<CardBody>
					<FormProvider {...methods}>
						<NewsletterForm
							loadgingSubmit={loading}
							onSubmit={onSubmit}
						/>
					</FormProvider>
				</CardBody>
			</Card>
		</Flex>
	);
};

export default CreateNewsletter;
